<template>
  <div class="pricing-table">
    <div
      v-for="plan in plans"
      :key="plan.id"
      :class="['pricing-plan', plan.highlight ? 'is-success' : '', plan.id == 3 ? 'is-link' : '']"
    >
      <div class="plan-header">{{ plan.name }}</div>
      <div class="plan-price">
        <span class="plan-price-amount">
          <span class="plan-price-currency">{{ plan.currency }}</span
          >{{ plan.price }}
        </span>
        <div style="display: inline-block; text-align: left">
          <span> per users</span><br />
          <span> per month</span>
        </div>
      </div>
      <div class="plan-items">
        <div v-for="(item, index) in plan.items" :key="index" class="plan-item">{{ item }}</div>
      </div>
      <div class="plan-footer">
        <div v-if="plan.free" class="plan-free">No signup required</div>
        <div v-else class="plan-btn">
          <!--  
          <button class="button is-fullwidth" :disabled="plan.currentPlan">Current plan</button> -->
          <button class="button is-fullwidth" @click="email(plan.name)">Get started</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    email(plan) {
      window.location.href = `mailto:team@bye.email?subject=Upgrading to ${plan} plan on bye.email&body=Hi! \nI'm interested in upgrading to the ${plan} plan on bye.email.`;
      // window.location.href = "mailto:
    },
  },
  data() {
    return {
      plans: [
        /* {
          id: 1,
          name: "Free",
          price: 0,
          currency: "$",
          items: ["1 team inbox", "2 team members", "1000 monthly messages", "-", "-", "-"],
          currentPlan: true,
          highlight: false,
          free: true,
        },*/
        {
          id: 2,
          name: "Free",
          price: 0,
          currency: "$",
          items: [
            //

            "30 days email retention",
            "No email summaries",
            "No contact enrichment",
            "No reply",

            //   "-",
            //   "-",
          ],
          btn: "Get started",
          currentPlan: false,
          highlight: false,
        },
        {
          id: 2,
          name: "Plus",
          price: 5,
          currency: "$",
          items: [
            //
            "2GB Storage",
            "50 emoji summaries",
            "20 contact enrichment",
            "Send quick replies",
            // "-",
            //  "-",
          ],
          currentPlan: false,
          highlight: true,
        },
        {
          id: 3,
          name: "Pro",
          price: 15,
          currency: "$",
          items: [
            //
            "20GB Storage",
            "250 emoji summaries",
            "250 contact enrichment",
            "Send quick replies",
            // "-",
            //   "-",
          ],
          currentPlan: false,
          highlight: false,
        },
      ],
    };
  },
};
</script>
<style scoped>
.plan-items {
}
.plan-item {
  margin: 2px 10px;
}
</style>
