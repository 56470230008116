<template>
  <div class=" ">
    <feed />
    <div class="section">
      <div class="container home" v-if="!loadingProj">
        <!-- 
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-12-tablet">
            <h1 class="title is-3">Your APIs</h1>
            <h1 class="subtitle is-5">Plan, prototype and manage API projects.</h1>
          

            <router-link :to="{ path: $store.state.userToken ? '/new' : '/login' }" active-class="is-active" exact>
              <b-button rounded>Add a new API</b-button>
            </router-link>
          </div>
          <div class="column">
            <div class="columns is-multiline">
              <div class="column is-6-tablet is-6-desktop is-4" v-for="i in projs" :key="i.id">
              
                <router-link :to="{ path: '/' + i.id }" active-class="is-active" exact>
                  <projCard :proj="i" />
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <router-link :to="{ path: '/explore' }">
          <b-button type="is-info is-outlined">Explore Public examples</b-button>
        </router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import feed from "@/components/feed.vue";
export default {
  name: "Home",
  components: {
    feed,
    //  HelloWorld,
    //   projCard,
  },
  data() {
    return {
      projs: [],
      loadingProj: true,
      publi: [],
      // loadingOps: true,
    };
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.publi = proj;
      this.loadingProjPubli = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/

    var u = this.$store.state.userId;

    //  this.projs = proj;
    this.loadingProj = false;

    /*
    window.API.getUserProjects(u).then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
