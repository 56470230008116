import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    test: "works!!!",

    userEmail: null,
    profile: null,
    userToken: localStorage.getItem("userToken") || "",
    userId: localStorage.getItem("userId") || "",
  },
  mutations: {
    setUserToken(state, token) {
      state.userToken = token;
      localStorage.setItem("userToken", token);

      window.API.configUserToken(token); //need to use this setter!
      // API.configUserToken(token);
      if (token) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
    setUserId(state, i) {
      state.userId = i;
      localStorage.setItem("userId", i);
      window.API.configUserId(i); // for api.me...
    },
    setAvatar(state, a) {
      state.avatar = a;
    },
    setHandle(state, a) {
      state.handle = a;
    },
    setCurrentUserProfile(state, a) {
      state.profile = a; //current user
    },
    logout(state) {
      // alert("logoutt");
      state.userToken = "";
      localStorage.removeItem("userToken");
      localStorage.removeItem("userId");
      localStorage.clear(); // for all Draft messages saved... prevents cross-acount contamination

      window.API.configUserToken("");
      window.API.configUserId("");
      window.PI.clearCache(); //some private stuff was still in memory.

      //this.commit("setUnreadNotifP", 0);
      //this.commit("setUnreadMsgP", 0);
      //this.commit("setCoinsBalance", 0);
      //this.commit("setRepostQuotaAvailable", 0);
    },
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        commit("setUserToken", "");
        commit("setUserId", "");
        resolve();
      });
    },
    loginPass({ commit, dispatch }, postedData) {
      console.log("login pass - store.");
      //console.log(user)
      return new Promise((resolve, reject) => {
        //var  =
        console.log(postedData);
        //commit('auth_request')
        //axios({url: 'http://localhost:3000/login', data: user, method: 'POST' })
        window.API.loginPass(postedData)
          .then((data) => {
            console.log("dataONSE SERVER: ", data);
            console.log(data);
            if (data && data.token) {
              //  alert('YAYYYY')
              var token = data.token || data.status;
              //this.state.avatar = data.avatar
              //token += Math.random();
              commit("setAvatar", data.avatar);
              commit("setHandle", data.handle);
              commit("setUserToken", token);
              commit("setUserId", data.userId);

              //const bodyEl = document.body;
              //bodyEl.classList.add('hideGoogleOnTap');

              //load th efresh data!
              dispatch("fetchCurrentUserProfile"); //can be called on any profile update from setting components.
              resolve(data);
            } else {
              //  alert('no data nor token...')
              console.log(data);
              dispatch("logout"); // server wasn't happy with the login  pass..
              reject(data);
            }
            //commit('auth_success', token, postedData)
          })
          .catch((err) => {
            commit("setUserToken", "");
            commit("setUserId", "");
            reject(err);
          });
      });
    },
    setProfileToken({ commit, dispatch }, p) {
      //to be called by alt method, like OTP.
      commit("setAvatar", p.avatar);
      commit("setHandle", p.handle);
      commit("setUserToken", p.token);
      commit("setUserId", p.userId);
      dispatch("fetchCurrentUserProfile");
    },
    fetchCurrentUserProfile({ commit, dispatch }, options) {
      //alert('refresh profile!')
      // return the Promise via `store.dispatch()` so that we know
      // when the data has been fetched
      var noCacheOpt = options || {};
      console.log("///fetchCurrentUserProfile: ", options);
      return (
        window.API.me
          // { noCache: 1 }
          .userProfileOwner(noCacheOpt) // will use current user by default - if any - heavier than public profile. has all the stuff!
          .then((data) => {
            if (!data) return null;
            console.log("👨👨 RESPONSE SERVER. fetchCurrentUserProfile : ", data);
            console.log(data);
            commit("setCurrentUserProfile", data);

            dispatch("updateCounts");
            //alert('profile  deettreceived.')
            //resolve(resp)
          })
          .catch((err) => {
            console.warn(" [Store] CANT fetchCurrentUserProfile, why did we call it ? ");
            //dispatch('logout')
            //    alert('error profile..')
            //commit("setUserToken", '');
            //commit("setUserId", '')
            //reject(err)
          })
      );
    },
    /*
    configurePlayground({ commit, dispatch }, p) {
      //to be called by alt method, like OTP.
      commit("setAvatar", p.avatar);
      commit("setHandle", p.handle);
      commit("setUserToken", p.token);
      commit("setUserId", p.userId);
      dispatch("fetchCurrentUserProfile");
    },*/
  },
  modules: {},
});
