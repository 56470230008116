<template>
  <b-navbar :transparent="true">
    <template #brand>
      <b-navbar-item class="noUnderline" tag="router-link" :to="{ path: '/' }">
        <!-- --  
        <emoji emoji="athletic_shoe" set="apple" :size="32" />
        <emoji emoji="hourglass" set="apple" :size="32" />
                <emoji emoji="sandwich" set="apple" :size="55" />
          
              <img src="/img/h120.png" />
          
          
                <emoji emoji="hamburger" set="apple" :size="32" />
         -->

        <!--    👟 🏁 🏆  🥎  🚧 🪙 🧰 🌀
          medal swirl star

          🚂
          🚌🛺
        https://jm-david.github.io/emoji-mart-vue/
       👟🥪 🧰 
           <emoji emoji="sandwich" set="apple" :size="55" />
        <img src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/325/toolbox_1f9f0.png" />


      -->

        <!-- 
        <span class="logosla" style="font-weight: 100"> /</span>
         -->
        <img src="/logo_bye.png" class="logo_bye" style="" />

        <!--  
        <img src="/img/bye1.png" style="width: 30px; margin-right: 7px" />

      

        <span style="letter-spacing: 2px"> <b style="font-weight: 500">BYE</b> </span
        ><b style="font-weight: 300; opacity: 0.5">.email</b>-->
      </b-navbar-item>
    </template>
    <template #start>
      <b-field label="" class="navbar-search" style="display: none">
        <b-autocomplete
          rounded
          size="is-small"
          v-model="autoTxt"
          type="search"
          :data="filteredDataArray"
          field="operationId"
          placeholder="search functions"
          icon="search"
          :keep-first="true"
          :loading="loadingMethod"
          :disabled="loadingMethod"
          :open-on-focus="false"
          @select="(option) => auto(option)"
        >
          <template #empty>No results found</template>
          <template slot-scope="props">
            {{ props.option.operationId }}
          </template>
        </b-autocomplete>
      </b-field>
    </template>

    <template #end>
      <!-- -- 
      {{ $store.state.test }}--- {{ $store.state.userId }}----
      {{ $store.state.userToken }}
       -->

      <!--  Private nav  
      <b-navbar-item v-if="$store.state.userToken" tag="router-link" :to="{ path: '/' }"> Dashboard </b-navbar-item>
-->

      <!-- --  
     
      <b-navbar-item v-if="$store.state.userToken" tag="router-link" :to="{ path: '/about' }">
        Documentation
      </b-navbar-item>

         
       -->
      <b-navbar-item v-if="!$store.state.userToken" @click="scrollToDiv"> Pricing </b-navbar-item>

      <b-navbar-item v-if="!$store.state.userToken" tag="router-link" :to="{ path: '/login' }"> Sign in </b-navbar-item>

      <!-- 
      <b-navbar-dropdown label="Info">
        <b-navbar-item href="#"> About </b-navbar-item>
        <b-navbar-item href="#"> Contact </b-navbar-item>
      </b-navbar-dropdown>
        -->

      <b-navbar-item tag="div" v-if="!$store.state.userToken">
        <div class="buttons">
          <router-link to="/" class="button is-dark">
            <strong style="color: white">Get started</strong>
          </router-link>
        </div>
      </b-navbar-item>

      <div class="navbar-menu" v-if="$store.state.userToken">
        <div class="navbar-end">
          <b-dropdown position="is-bottom-left" append-to-body aria-role="menu">
            <template #trigger>
              <a class="navbar-item" role="button">
                <b-icon icon-pack="fal" icon="user-circle"></b-icon>
                <span>{{ $store.state.userId }}</span>
                <b-icon icon-pack="fal" icon="caret-down"></b-icon>
              </a>
            </template>

            <b-dropdown-item custom aria-role="menuitem">
              Logged as <b>{{ $store.state.userId }}</b>
            </b-dropdown-item>
            <hr class="dropdown-divider" />

            <!-- ---->
            <b-dropdown-item value="home" aria-role="menuitem" has-link>
              <router-link :to="{ path: '/' }">
                <span class="icon"><i class="fal fa-home"></i></span>

                Home
              </router-link>
            </b-dropdown-item>

            <b-dropdown-item has-link aria-role="menuitem">
              <router-link :to="{ path: '/org/invt' }">
                <span class="icon"><i class="fal fa-briefcase"></i></span>
                Organisation
              </router-link>
            </b-dropdown-item>

            <!-- 
            <b-dropdown-item value="products" aria-role="menuitem">
              <b-icon icon="cart"></b-icon>
              Products
            </b-dropdown-item>


            <b-dropdown-item value="blog" disabled aria-role="menuitem">
              <router-link :to="{ path: '/' }">
                <b-icon icon="home"></b-icon> Home

                <b-icon icon="book-open"></b-icon>
                Blog
              </router-link>
            </b-dropdown-item>
  -->

            <hr class="dropdown-divider" aria-role="menuitem" />
            <b-dropdown-item value="settings" has-link>
              <router-link :to="{ path: '/settings' }">
                <span class="icon"><i class="fal fa-cog"></i></span>
                Settings
              </router-link>
            </b-dropdown-item>

            <b-dropdown-item value="logout" aria-role="menuitem" @click="logUserOut">
              <span class="icon"><i class="fal fa-sign-out"></i></span>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </template>
  </b-navbar>
</template>

<script>
import { Emoji } from "emoji-mart-vue";

export default {
  components: {
    //  Emoji,
  },
  data() {
    return {
      ops: [],
      autoTxt: "",
      selected: null,
      loadingMethod: true,
    };
  },
  mounted() {},
  methods: {
    scrollToDiv() {
      const element = document.getElementById("pricing");
      element.scrollIntoView({ behavior: "smooth" });
    },
    logUserOut() {
      // alert(3);
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/?seeyousoon=1");
      });
      /*
            localStorage.removeItem("userAuthToken");
            this.$router.push("/login");
            alert('logged out. token removed brooooo')
            */
    },
    auto: function (opt) {
      if (!opt) return;
      var slug = opt.operationId;
      if (!slug) return;
      // `this` inside methods point to the Vue instance
      // alert("go to " + slug + "!");
      var c = this.currentDash;
      this.$router.push({
        name: "operationHome",
        params: { operation: slug, project: c },
      });
      this.autoTxt = "";
      // `event` is the native DOM event
      //alert(event.target.tagName)
    },
    greet: function (event) {
      // `this` inside methods point to the Vue instance
      alert("Hello " + this.name + "!");
      // `event` is the native DOM event
      // alert(event.target.tagName);
    },
  },
  computed: {
    currentDash() {
      return "DashBoardApi";
    },
    filteredDataArray() {
      return this.ops.filter((option) => {
        // we make one big string of all the keywords.
        var keywords = option.operationId + " " + option.path + " " + option.desc;
        if (option.tags && option.tags.length) keywords += " " + option.tags.join(" ");
        // console.log(keywords);
        return keywords.toString().toLowerCase().indexOf(this.autoTxt.toLowerCase()) >= 0;
      });
    },
  },
};
</script>

<style scoped>
.logosla {
  font-size: 33px;
  line-height: 35px;
  font-weight: 100;
  margin-top: -9px;
}

.logo_bye {
  margin-right: 7px;
  min-height: 50px;
  margin-top: -20px;
  margin-left: -15px;
}
</style>
