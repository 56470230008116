<template>
  <div class="homewrap">
    <HomeLogged v-if="$store.state.userToken" />
    <div v-else>
      <Login v-if="$route.query.source == 'homescreen'" />
      <HomePublic v-else />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import HomeLogged from "@/views/HomeLogged.vue";
import HomePublic from "@/views/HomePublic.vue";
import Login from "@/views/Login.vue";
export default {
  name: "Home",
  components: {
    //  HelloWorld,
    Login,
    HomePublic,
    HomeLogged,
    // projCard,
  },
  data() {
    return {
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
