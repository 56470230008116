<template>
  <div>
    <img :src="getImageUrl()" alt="File icon" />
  </div>
</template>

<script>
import { getMaterialFileIcon } from "file-extension-icon-js";

export default {
  name: "FileIcon",
  props: {
    filename: {
      type: String,
      required: true,
    },
  },
  methods: {
    getImageUrl() {
      //return 2;
      const extension = this.filename.split(".").pop().toLowerCase();
      const icon = getMaterialFileIcon(extension);
      console.log(icon);
      console.log(extension);
      return icon;
      return `data:image/svg+xml;utf8,${icon}`;
    },
  },
};
</script>
