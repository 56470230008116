import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const inboxChildren = [
  {
    path: ":messageId",
    component: () => import("../views/MessageDetails.vue"),
    // name: "messageDetails",
  },
];

const routes = [
  {
    path: "/img/:catchAll(.*)*",
    component: () => import("../views/e404.vue"),
  },
  {
    path: "/invite/:inviteId",
    component: () => import("../views/Invite.vue"),
  },
  {
    path: "/org/:orgId",
    component: () => import("../views/Dashboard.vue"),
  },

  ///share/message/:messageId
  {
    path: "/share/message/:messageId/:shareKey",
    component: () => import("../views/SharedMessage.vue"),
  },

  {
    path: "/contacts",
    component: () => import("../views/AccountSettings.vue"),
  },
  {
    path: "/u/:handle",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/contacts/:orgId",
    component: () => import("../views/AccountSettings.vue"),
  },
  {
    path: "/contacts/:orgId/:contactId", //emaml
    component: () => import("../views/AccountSettings.vue"),
  },

  {
    path: "/screen",
    component: () => import("../views/AccountSettings.vue"),
  },

  {
    path: "/domains",
    component: () => import("../views/AccountSettings.vue"),
  },

  {
    path: "/settings",
    component: () => import("../views/AccountSettings.vue"),
  },

  {
    path: "/",
    name: "Home",
    component: Home,
    //children: inboxChildren,
    //childresns for "notifications", junk, starred, teams, etc
  },

  {
    path: "/home/:messageId",
    component: () => import("../views/Inbox.vue"),
  },
  {
    path: "/updates",
    component: () => import("../views/Inbox.vue"),
    name: "updates",
    //childrens for mesage details route
    //  children: inboxChildren,
  },
  {
    path: "/updates/:messageId",
    component: () => import("../views/Inbox.vue"),
    //  name: "updatesDeets",
    //childrens for mesage details route
    //  children: inboxChildren,
  },
  {
    path: "/team",
    component: () => import("../views/Inbox.vue"),
    name: "team",
    children: inboxChildren,
  },
  {
    path: "/promos",
    component: () => import("../views/Inbox.vue"),
    name: "promos",
    children: inboxChildren,
  },
  {
    path: "/sent",
    component: () => import("../views/Inbox.vue"),
    name: "sent",
    children: inboxChildren,
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
