<template>
  <div class="container home">
    <div class="box" style="max-width: 500px; margin-top: 50px">
      <div class="column">
        <h1 class="title is-3">Welcome back!</h1>

        <!--   
        <b-button @click="loginGoogle">Google sign in!</b-button>,
        -->

        <b-field horizontal label="Username">
          <b-input
            size="is-largeNO"
            placeholder="ex: spiderbob"
            v-model="user"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-field horizontal v-model="pass" label="Password">
          <b-input v-model="pass" value="123" type="password" maxlength="30"></b-input>
        </b-field>
        <b-field horizontal>
          <b-button @click="submit" :loading="posting" :disabled="posting">Sign in</b-button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";

//import * as GoogleInit from "../../../utils/googleOauth";

export default {
  name: "Home",
  components: {
    //  HelloWorld,
    //  projCard,
  },
  data() {
    return {
      p: {},
      user: "",
      pass: "",
      //   projs: [],
      posting: false,
      // loadingOps: true,
    };
  },
  methods: {
    submit() {
      // Send data to the server or update your stores and such.
      this.posting = true;
      /*
      window.API.loginPass()
        .then((data) => {
          this.$buefy.toast.open({
            message: "Loading your profile...",
            type: "is-success",
          });
*/
      var posted = { h: String(this.user).trim().toLowerCase(), p: String(this.pass).trim() };
      this.$store
        .dispatch("loginPass", posted)
        //.dispatch("setProfileToken", data)
        .then(() => {
          //alert('TODO: close login modal mutation...')
          this.$router.push("/?justLogged=1");
        })
        .catch((err) => {
          console.log(err);
          console.log("ERR AUTH ........");

          //buefy toast
          this.$buefy.toast.open({
            message: "Wrong username or password",
            type: "is-danger",
          });

          // this.$router.push("/login?wrongpass=1");
        })
        .then((succ) => {
          this.posting = false;
        });
    },
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
      window.API.getProjectOperations(this.$route.params.project).then((ops) => {
        var opId = this.$route.params.operation;
        this.ops = ops;
        this.loadingOps = false;
        this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
      });*/
  },
};
</script>

<style></style>
