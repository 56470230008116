<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding: 0; margin: 0; padding-top: 60px; padding-bottom: 4rem">
        <div class="container" style="padding: 20px">
          <br />
          <br />

          <!-- 



🤑💸 Stop wasting your marketing budget on the wrong audience. Use QR code retargeting to target the right people and watch your ROI soar!
🚫❌ Don't let your marketing efforts go to waste! Try QR code retargeting and make sure you're reaching the right people.
🤔🤷‍♂️ Not sure if you're reaching the right audience with your marketing campaigns? Use QR code retargeting and be confident that you are!
💰🔥 Why burn through your marketing budget without results? Try QR code retargeting and get the most out of your spend.
🎯🎉 Reach the right people with your marketing efforts and celebrate your success! Use QR code retargeting and get the results you want.



        -->
          <p class="title is-3">
            Liberate your email inbox.

            <!--  💰🔥

                🙅‍♀️ Goodbye Distractions, Hello Productivity
                
            Title: "🚀 Crush Your Inbox, Elevate Your Game"
Subtitle: "⏱️ Take Control of Your Time and Achieve More with Our Minimalist Email App That Keeps You Focused and Productive"

Title: "📧 Zero Clutter, All Hustle"
Subtitle: "💥 Turbocharge Your Email Efficiency and Cut Through the Noise with Our Read-only, Action-focused Email App"

Title: "🙅‍♀️ Goodbye Distractions, Hello Productivity"
Subtitle: "🤫 Quiet Your Inbox and Amplify Your Output with Our Minimalist Email App That Helps You Stay on Task and Get Stuff Done"

Title: "👋 Sayonara, Email Overload"
Subtitle: "🛡️ Protect Your Time and Sanity with Our Read-only, Action-oriented Email App That Filters Out the Noise and Delivers Results"

Title: "🎯 Simplify Your Email, Supercharge Your Success"
Subtitle: "🚀 Streamline Your Communication and Achieve Your Goals with Our Powerful, No-frills Email App That Helps You Get Things Done"
         -->
          </p>
          <p class="subtitle is-3">
            <!-- Cut the noise with a new kind of email inbox. 
            Cut the noise & s -->

            Stay productive with a drastically different inbox.
          </p>

          <div style="max-width: 500px">
            <waitlist />
          </div>
          <!-- 
          <b-button native-type="submit" type="  is-dark" style="text-transform: uppercase; letter-spacing: 1px">
            TRY IT NOW
          </b-button>
        -->
          <br />
          <em style="color: black; opacity: 0.2">Free, even with custom domains</em>

          <!--     <qrform /> -->
        </div>
      </div>
    </section>
    <br />
    <br />
    <div class="section">
      <div class="container c1200">
        <p class="title is-3">It's frockin' read-only 💥</p>
        <p class="subtitle is-5">You'll love it, trust us</p>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="feature in keyFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <div class="section">
      <div class="container c1200 promoMenu" style="">
        <div style="text-align: center">
          <h2 class="title is-3">❌ 90% of your inbox is utter garbage 🚫</h2>
          <h2 class="subtitle is-4">Fix your company emails in 10 minutes</h2>
          <br />
        </div>
        <div v-show="false">
          <promoMenu />
        </div>
      </div>
      <hr />
    </div>

    <div class="section pricing" id="pricing">
      <div style="text-align: center">
        <!--    <h2 class="title is-3">Pro plans packed with value</h2>
        <h2 class="subtitle is-4">Free forever plan let you own your email.</h2> -->
        <h2 class="title is-3">Profesional inboxes, fair prices.</h2>
        <h2 class="subtitle is-4" style="max-width: 780px; text-align: center; margin: auto">
          All plans includes a @BYE address, custom domains, email&nbsp;forwarding, unlimited mailboxes, unlimited users
          &amp;&nbsp;all&nbsp;core&nbsp;features.
        </h2>
        <br />
      </div>
      <div class="container c1200 pricing">
        <pricing />
      </div>
    </div>

    <div class="section" id=" ">
      <div class="container" style="text-align: left">
        <p class=" ">© bye.email, {{ new Date().getFullYear() }} 👋</p>
      </div>
    </div>

    <!--  
    <div class="section">
      <div class="container c1200 guide">
        <guide />
      </div>
    </div>
 -->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Fast & focused",
    description: "Important messages are surfaced in your feed; promos stays in the trash.",
  },

  {
    title: "Action-driven",
    description:
      "See login your authentication codes, meeting invites, attachements and action buttons right on your homescreen. ",

    //"Stop the back and forth of opening emails one by one. See login your authentication codes, attachements and action buttons right on your homescreen. Simple & productive ",
  },

  {
    title: "We don't click around  ",
    description:
      "Built for mobile. Quickly scroll over notifications and promos without having to tap each email (and the back button in between).",

    //"Stop the back and forth of opening emails one by one. See login your authentication codes, attachements and action buttons right on your homescreen. Simple & productive ",
  },

  {
    title: "No discussions, no distractions",
    description:
      " Keep conversations in Slack, Discord, Social, Sales CRM, support Helpdesks or Tinder if you like. Using your email for everything is counter-productive. ",
  },
  {
    title: " Anxiety-free ",
    description: "There's no point replying to emails that don't matter. Your inbox won't be a to-do list anymore.  ",
  },
  {
    title: "Many personas, one dashboard",
    description:
      "Pet project, work accounts, new instagram. Receive notifications and retrieve credentials for all your accounts without the logout-login dance.",
  },
  {
    title: "Team Inboxes ",
    description: `Share email adresses with your team. No more need for endless forwarding and CC'ing of emails. `,
    //`Share email folders with your team. No more receipt CC and one email FW. `,
  },

  {
    title: "Ad-free & tracking-free",
    description:
      "We're a small indie company. We don't sell your data to advertisers. We charge a fair price for our service and that's it. ",
  },

  {
    title: "Skip the abusive prices",
    description: "Business email accounts shouldn't cost a fortune. Starts with a generous free plan.",
  },

  // : "Affordable & reliable",

  // HOW TO SUSRIVE THE SWITCH TO BYE
  // 1. Keep it simple.
  // Don't start conversations. Since you won't write anyone from this adress, only marketers might email you to your BYE adress.

  // Use the right tools.
  // 2. Keep conversations in best in class tools. Slack/Discord for internal messages, CRM, Helpdesk Discord and other apps.
];

/*

  {
    title: "Conversations deserve better",
    description:
      "We got social media for friends. Slack and Discord for internal messaging. Sales CRM, mailing lists, and other support helpdesk. There's no need to use email for everything. ",
  },

Liberate your email from chatter.
Keep conversations in Slack, CRM, Helpdesk Discord and other apps. 



How ill I reply??

Forward your emails to BYE - you can 



1. Keep it . 
Don't start conversations. Since you won't write anyone from this adress, only marketers might email you to your BYE adress.

Use the right tools.
2. Keep conversations in best in class tools. Slack/Discord for internal messages, CRM, Helpdesk Discord and other apps.



3. 3. Don't reply to emails.
Instead, forward them to the appropriate channel. 
3. Use a personal email adress for








Conversations deserve better.



*/
/*[
  {
    title: "Speed Up Full-Stack Development",
    description:
      "Midrun centralizes all key information to save you time. Our development tools streamline the process, keeping specifications in sync and simplifying debugging.",
  },
  {
    title: "Effortless API Generation",
    description:
      "Midrun's tools help you write your API specification and generate draft code for all functions with ease. Whether you're an experienced coder or just starting out, you can easily adjust the details to fit your needs.",
  },
  {
    title: "Freedom to Customize",
    description:
      "Our open-source based toolkit gives you the flexibility you need to succeed. Avoid platform and vendor lock-ins and scale with ease, without any hidden fees. Midrun gives you the freedom to build the solution that's right for you.",
  },
];
*/
/*


[
  {
    title: "AI-Optimized API Development",
    description:
      "Midrun leverages AI algorithms to optimize API development, freeing up time and improving efficiency.",
  },
  {
    title: "Predictive Coding",
    description: "Real-time code suggestions and completion to save time and reduce errors.",
  },
  {
    title: "Flexible Toolkit",
    description: "Customizable options to fit your specific needs and build the APIs you need to succeed.",
  },
];*/

export default {
  name: "Home",
  components: {
    // qrform, //
    waitlist,
    //  guide,
    promoMenu,
    pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "BYE.email - The inbox for people who hate emails", //"ApiBros 😎",
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      keyFeatures,
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
  },
};
</script>
<style scoped>
.container.c1200 {
  max-width: 1100px !important ;
}
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);

  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
  background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);
}
</style>
