<template>
  <div :style="avatarStyles">
    <img
      v-if="!majorWebmail"
      :src="`https://www.google.com/s2/favicons?domain=${fromTld}&sz=128`"
      :style="avatarStyles"
    />
    <avatar v-if="majorWebmail" :fullname="fullname || email" :size="size" />
    <!--  -->
    <img v-if="hasImage" :src="image" :alt="alt" />
    <div v-else class="initials">{{ initials }}</div>
  </div>
</template>

<script>
import Avatar from "vue-avatar-component";

const MAJOR_WEBMAIL = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com", "aol.com", "icloud.com"];

export default {
  name: "byeAvatar",
  props: {
    fullname: {
      type: String,
      required: true,
    },
    email: {
      type: String,
    },
    fromTld: {
      type: String,
    },
    size: {
      type: Number,
      default: 38,
    },
    alt: {
      type: String,
      default: "Avatar",
    },
  },
  components: {
    Avatar,
  },
  computed: {
    majorWebmail() {
      if (!this.fromTld) return true; //use txt
      return MAJOR_WEBMAIL.includes(this.fromTld);
    },
    initials() {
      return this.fullname
        .split(/\s+/)
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase();
    },
    hasImage() {
      return /^https?:\/\//i.test(this.image);
    },
    image() {
      //   :src="`https://www.google.com/s2/favicons?domain=${msg.fromTld}&sz=128`"
      return `https://www.google.com/s2/favicons?domain=${this.fromTld}&sz=${this.size}`;
    },
    avatarStyles() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderRadius: "50%",
        overflow: "hidden",
        display: "inline-block",
      };
    },
  },
};
</script>
