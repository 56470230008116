<template>
  <span>
    <b-dropdown aria-role="list" :portal="true">
      <template #trigger="{}">
        <!--  padding-left: 20px; padding-right: 1em;  -->
        <span style="vertical-align: text-top; cursor: pointer">
          <b-button icon-pack="far" type="is-text" rounded>
            <i :class="horizontal ? 'fas fa-ellipsis-h' : 'fas fa-ellipsis-v'" aria-hidden="true"></i>
          </b-button>
        </span>
      </template>

      <template v-for="(i, index) in menuItems">
        <b-dropdown-item v-if="i.click" aria-role="listitem" @click="i.click" :key="i + index" tag="strong">
          <i :class="i.icon" aria-hidden="true" v-if="i.icon"></i>
          <!-- -->

          {{ i.t }}
        </b-dropdown-item>
        <b-dropdown-item v-if="i.to" aria-role="listitem" :key="i + index" tag="strong">
          <router-link :to="i.to">
            <i :class="i.icon" aria-hidden="true" v-if="i.icon"></i>
            <!-- -->
            {{ i.t }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item v-if="i.href" aria-role="listitem" :key="i + index" tag="strong">
          <a :href="i.href" target="_blank">
            <i :class="i.icon" aria-hidden="true" v-if="i.icon"></i>
            <!-- -->
            {{ i.t }}
          </a>
        </b-dropdown-item>
      </template>
      <!--      <b-dropdown-item aria-role="listitem"> Action</b-dropdown-item>
      <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item>
      <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
    </b-dropdown>
  </span>
</template>

<script>
export default {
  name: "dotDrop",
  components: {
    // Doughnut,
  },
  props: {
    items: {
      type: Array,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    hitF: {
      type: Number,
      default: 0,
    },
    hitS: {
      type: Number,
      default: 0,
    },
    miss: {
      type: Number,
      default: 0,
    },
    dynamic: {
      type: Number,
      default: 0,
    },
    chartId: {
      type: String,
      default: "doughnut-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    menuItems() {
      return this.items;
      /*
      {
        labels: ["Fresh Hits", "Stale Hits", "Miss", "Dynamic", "Error"],
        datasets: [
          {
            backgroundColor: ["#1b4e6b", "#5c63a2", "#c068a8", "#ec7176", "#f4ab33"],
            data: [this.hitF, this.hitS, this.miss, this.dynamic, 0],
          },
        ],
      };
      */
    },
  },
};
</script>
<style scoped>
.tag {
  cursor: pointer;
}
</style>
