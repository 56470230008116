<template>
  <div class="" v-if="msg">
    <div class="card msg" :class="{ expanded: expanded, expanded2: expanded2, read: msg.read || this.read }">
      <div class="whitefill" @click="clickToExpand">
        <!-- TODO: AMKE CARDFEED COMPONENT" -->
        <div class="card-content" @dblclick="msgDblCkick(msg._id)">
          <div class="msg-header">
            <!-- 
              <img :src="msg.gravatar" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 10px" />
 -->
            <div style="float: right; margin-left: 10px; margin-top: 5px">
              <DotDrop :horizontal="true" :items="dropDownItems" />

              <!-- 
            <a :href="getMailtoHref(msg)" target="_blank" class="button is-small">
              <strong>FW</strong>
            </a>

            <b-button @click="copyLink(msg)" icon-pack="far" type="is-text" rounded>
              <i class="fas fa-bucket" aria-hidden="true"></i>
            </b-button> -->

              <b-button @click="closeBtn(msg._id)" icon-pack="far" type="is-text" rounded>
                <i class="fas fa-times" aria-hidden="true"></i>
              </b-button>
            </div>

            <div class="from-block">
              <router-link :to="`/contacts/${orgId}/${msg.parsed.from.address}`" class="" avat>
                <Avatar
                  :fullname="msg.parsed.from.name || msg.parsed.from.address"
                  :email="msg.parsed.from.address"
                  :fromTld="msg.fromTld"
                  :size="38"
                  style="float: left; margin-right: 10px"
                />
                <!-- 
                <img
                  v-if="!msg.majorWebmail"
                  :src="`https://www.google.com/s2/favicons?domain=${msg.fromTld}&sz=128`"
                  style="width: 38px; height: 38px; float: left; margin-right: 10px"
                />

                <avatar
                  v-if="msg.majorWebmail"
                  :fullname="msg.parsed.from.name || msg.parsed.from.address"
                  :size="38"
                  style="float: left; margin-right: 10px"
                />  -->
              </router-link>
              <span class="msg-from">
                <b-tooltip :label="msg.parsed.from.address" type="is-dark" position="is-right" :delay="1000">
                  <span @click.stop="clickAddress(msg.parsed.from.address)">
                    <small> {{ msg.parsed.from.name || msg.parsed.from.address || "Unknown" }}</small>
                  </span>
                </b-tooltip>

                <br />

                <b-tooltip :label="msg.date" type="is-dark" :delay="2000">
                  <span>
                    <small>{{ timeAgo(msg.date) }}</small>
                  </span>
                </b-tooltip>

                <!--  tag with email address sen to
               -->
                <span class="is-small">
                  <span class="tag is-info is-light is-small" style="margin-left: 10px"
                    >{{ msg.parsed.to[0].address }}
                  </span>
                  <small> +3</small>
                </span>
              </span>
            </div>
          </div>

          <div v-if="showMeta" class="message is-warning" @click="showMeta = !showMeta">
            <h3 class="title is-size-5">Additional Metadata</h3>
            <ul>
              <li><strong>To:</strong> {{ msg.parsed.headers.to }}</li>
              <li><strong>CC:</strong> {{ msg.parsed.headers.cc }}</li>
              <li><strong>BCC:</strong> {{ msg.parsed.headers.bcc }}</li>
              <li><strong>Reply-To:</strong> {{ msg.parsed.headers["reply-to"] }}</li>
              <li><strong>In-Reply-To:</strong> {{ msg.parsed.headers["in-reply-to"] }}</li>
              <li><strong>References:</strong> {{ msg.parsed.headers.references }}</li>
            </ul>
          </div>

          <div class="card-content sniped code" :class="{ copied: copied }" v-if="msg.code">
            <div class="authCode" @click="clickCode" style="">{{ msg.code }}</div>
            <!-- small button to show all content original msg-->
          </div>

          <b-button
            @click="
              () => {
                showBody = true;
              }
            "
            v-show="hasSnipet && !showBody"
            icon-pack="far"
            type="is-text"
            rounded
            style="display: block"
          >
            <i class="fal fa-envelope" aria-hidden="true"></i>
            <span class="is-size-7">View message</span>
          </b-button>

          <b-loading :is-full-page="false" :active="summerazing" :can-cancel="false"></b-loading>
          <div class="summary subtitle is-5 content" style="margin-top: 20px; color: #aaa">
            <div>
              {{ newSummary || msg.summary }}
            </div>

            <!--  
            <blockquote>
              {{ newSummary || msg.summary }}
            </blockquote>
        -->
          </div>

          <div class="msg-body" v-show="!hasSnipet || showBody">
            <div class="msg-subject">
              <h3 class="title is-5">{{ msg.parsed.subject }}</h3>
            </div>
            <div class="msg-text">
              <shadow-root v-if="format == 'html'">
                <div class="content" v-html="msg.parsed.html || msg.parsed.text"></div>
              </shadow-root>

              <p v-if="format == 'text'" class="lineclamp4NO" style="margin: 0; white-space: pre-line">
                <v-clamp autoresize :max-lines="hasSnipet ? 9 : 3" :expanded="expandText"
                  >{{ msg.parsed.text }}

                  <template #after="{ toggle, expanded, clamped }">
                    <b-button
                      v-if="clamped && !expanded"
                      size="is-small"
                      class="btSeeMore"
                      rounded
                      type="    is-text  "
                      @click.stop="toggle"
                    >
                      See more
                    </b-button>
                  </template>
                </v-clamp>
              </p>

              <!--  
            ---------------
            <p id="snippet-1">{{ elipsis(msg.parsed.text, 70) }}</p> -->
            </div>
          </div>
        </div>

        <footer class="card-content">
          <!-- download file button with image preview file icon -->
          <span
            v-if="true || msg.parsed.attachments.length > 0"
            class="card-footer-itemNOO"
            style="border: 2px solid #ccc; border-radius: 14px; padding: 3px 10px; display: inline-block"
          >
            <div v-for="att in msg.parsed.attachments" :key="att.filename" class="att">
              <!--  
                <img :src="fileIconImg(att)" :alt="att.mimeType" style="height: 30px; margin-right: 10px" />
              {{ att.filename }} -->

              <div>
                <file-icon :filename="att.filename" style="float: left; margin-right: 5px" />
                <span>
                  <span>{{ att.filename }}</span
                  ><br />
                  <span class="has-text-link">
                    <span class="icon"> <i class="fal fa-download" aria-hidden="true"></i> </span>Download</span
                  >
                </span>
              </div>
            </div>

            <!--  
            <img :src="fileIcon(msg.attachments)" style="height: 30px; margin-right: 10px" />
            FILENAME here .pdff
            <span class="icon">
              <i class="fal fa-download" aria-hidden="true"></i>
            </span>
-->
          </span>
        </footer>
        <footer class="card-footer" v-show="msg.hideFoot">
          <a href="#" class="card-footer-item">
            <span class="icon">
              <i class="fal fa-bookmark" aria-hidden="true"></i>
            </span>
            Keep
          </a>

          <a href="#" class="card-footer-item">Trash</a>
          <a href="#" class="card-footer-item">
            <span class="icon">
              <i class="fal fa-share" aria-hidden="true"></i>
            </span>
            Share</a
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
//import faker from 'faker'
//import VuePaginate from "vue-paginate";
/*
window.inbox = {};
            window.paginate = {
                total: Math.random() * (54236 - 100) + 3
            }
            for (var i = 0; i <= 10; i++) {
                window.inbox[i] = {
                    from: faker.name.findName(),
                    timestamp: null,
                    subject: faker.lorem.sentence().substring(0, 40),
                    snippet: faker.lorem.lines(),
                    fullMail: window.faker.lorem.paragraphs(faker.random.number(40)),
                    email: faker.internet.email()
                };
            }
*/

//export vue compoenn
import Avatar from "@/components/avatar.vue";

import VClamp from "vue-clamp"; //needs some config.vue setup...https://justineo.github.io/vue-clamp/demo/
import FileIcon from "./FileIcon.vue";
import MessageDetailsModal from "../components/MessageDetailsModal.vue";
// import elipsis from utils
import {
  formatPlainTextInHtml,
  removeExtraSpacesAndLines,
  removeEmailHeaders,
  elipsis,
  timeAgo,
  replaceUrls,
  truncateTextWords,
} from "../common/utils";

// DotDrop
import DotDrop from "../components/DotDrop.vue";

export default {
  name: "inbox",
  //props: receive "msg" from parent
  props: {
    orgId: {
      type: String,
      default: "personal",
    },
    msg: {
      type: Object,
      required: true,
    },
    defaultFormat: {
      type: String,
      default: "html",
    },
  },

  components: {
    Avatar,
    VClamp,
    FileIcon,
    DotDrop,
    // MessageDetailsModal,
    // VuePaginate
  },
  //props for messages
  data() {
    return {
      //  messages: [],
      // hasSnipet: this.code || this.action,
      expandText: false,
      showMeta: false,
      showBody: false,
      copied: false,
      newSummary: "",
      summerazing: false,
      format: this.defaultFormat,
      expanded: false, //from preview height
      expanded2: false,
    };
  },
  //load msg from API on load
  mounted() {
    // this.getMessages();
  },
  computed: {
    /* getFormat() {
      return "plain";
      return this.msg.parsed.format;
    },*/

    dropDownItems() {
      var msg = this.msg;
      if (!msg) return [];
      return [
        {
          t: "Email details...",
          icon: "far fa-info",
          click: () => {
            this.showMeta = !this.showMeta;
          },
        },
        {
          t: "Copy link",
          icon: "far fa-link",
          click: () => {
            this.copyLink(msg);
          },
        },
        {
          t: "Share...",
          icon: "far fa-globe",
          to: this.getShareUrl(msg),
        },

        { t: "Open...", icon: "far fa-eye", to: this.getMsgDetailPath(msg._id) },

        { t: "Gmail Fwd ", icon: "far fa-envelope", href: this.getMailtoHref(msg, "fw") },
        { t: "Gmail Re ", icon: "far fa-envelope", href: this.getMailtoHref(msg) },

        {
          t: "Info ",
          icon: "far fa-info-circle",

          click: this.toggleMeta,
        },
        {
          t: "Summarize  ",
          icon: "far fa-info-magic",

          click: this.getEmailSummary,
        },

        {
          t: this.format == "html " ? "Plain text" : "HTML",
          icon: "far fa-" + (this.format == "html " ? "file-alt" : "code"),

          click: () => {
            this.format = this.format == "html" ? "text" : "html";
          },
        },
      ];
    },

    hasSnipet() {
      return this.msg.code || this.msg.action;
    },
    formatedPlainText() {
      // NO WORK!
      var t = this.msg.parsed.text;
      return formatPlainTextInHtml(t);
    },
    //get current route
    isDetailModalActive() {
      return this.$route.params.messageId;
    },
    selectedEmail() {
      return this.messages.filter((message) => {
        console.log(message._id, this.$route.params.messageId, 5558874);
        return message._id === this.$route.params.messageId;
      })[0];
      // return this.$store.state.selectedEmail;
    },
  },
  methods: {
    clickToExpand() {
      // this.expanded = !this.expanded;
      if (this.expanded) this.expanded2 = true;
      if (!this.expanded) this.expanded = true;
    },
    toggleMeta() {
      this.showMeta = !this.showMeta;
    },
    getShareUrl(msg) {
      return "/share/message/" + msg._id + "/" + msg.shareKey;
    },

    clickCode: function (msg) {
      //console.log("click code", msg);
      navigator.clipboard
        .writeText(this.msg.code)
        .then(() => {
          console.log("Async: Copying to clipboard was successful!");
          this.copied = true;
          //disable after 3 seconds
          setTimeout(() => {
            this.copied = false;
          }, 3000);
          //toast
          // buefy toast
          this.$buefy.toast.open({
            message: "Code copied to clipboard",
            type: "is-success",
            position: "is-top",
            duration: 3000,
            queue: false,
          });
        })
        .catch((err) => {
          console.error("Async: Could not copy text: ", err);
        });
    },
    clickAddress(a, e) {
      //e.stopPropagation();
      navigator.clipboard
        .writeText(a)
        .then(() => {
          console.log("Async: Copying to clipboard was successful!");

          this.$buefy.toast.open({
            message: " Copied to clipboard  ✂️  " + a + " ",
            type: "is-dark",
            position: "is-top",
            duration: 3000,
            queue: false,
          });
        })
        .catch((err) => {
          console.error("Async: Could not copy text: ", err);
        });
      return false;
    },

    copyLink(msg) {
      console.log("copy link", msg);

      //copy to clipboard
      // var host = window.location.hostname == "localhost" ? "http://localhost:4499" : "https://bye.email";
      var host = window.location.hostname == "localhost" ? "http://localhost:4499" : "https://bye.email";
      var txt = host + this.getShareUrl(msg); //host + "/share/message/" + msg._id + "/" + msg.shareKey;

      navigator.clipboard
        .writeText(txt)
        .then(() => {
          console.log("Async: Copying to clipboard was successful!");

          //toast
          // buefy toast
          this.$buefy.toast.open({
            message: "Link copied to clipboard",
            type: "is-dark",
            position: "is-top",
            duration: 3000,
            queue: false,
          });
        })
        .catch((err) => {
          console.error("Async: Could not copy text: ", err);
        });
    },
    msgDblCkick(id) {
      var to = this.getMsgDetailPath(id);
      this.$router.push(to);
    },
    //get file icon
    fileIconImg: function (filename) {
      var googleDocs = "https://cdn-icons-png.flaticon.com/512/5968/5968517.png";
      return googleDocs;
      //use a cloud service for icons images
      //https://www.iconfinder.com/iconsets/file-icons
      //https://www.iconfinder.com/iconsets/file-icons-2

      let ext = filename.split(".").pop();
      return "/img/file-icons/" + ext + ".png";
    },
    timeAgo: function (date) {
      return timeAgo(date);
    },
    closeModal: function () {
      //do reouter back previous url
      this.$router.go(-1);
      //this.$router.push({ name: "inbox" });
    },
    elipsis: function (str, len) {
      return elipsis(str, len);
    },
    //get messages from API
    async getEmailSummary() {
      // alert(32466);
      var t = String(this.msg.parsed.text);
      // sanitize text, anonimize ppi and emails:
      //phone
      t = removeEmailHeaders(t);
      t = removeExtraSpacesAndLines(t);

      t = t.replace(/(\d{3})\d{3}(\d{4})/g, "$1***$2"); // phone

      //emails
      t = t.replace(/([\w.]*@[\w.]*\.[\w]{2,4})/g, "****@****.***");
      t = replaceUrls(t);
      t = truncateTextWords(t, 600);
      this.summerazing = true;
      window.API.summarizeText({ text: t, id: this.msg._id })
        .then((data) => {
          this.summerazing = false;
          this.newSummary = data;
        })
        .catch((error) => {
          this.summerazing = false;
          console.log(error);
        });
    },
    closeBtn: function (id) {
      alert(id);
      //call API to delete message, flag it as "closed"
      // and collapse the message card
    },

    getMailtoHref(msg, reOrFwd) {
      var from = msg.parsed.from.address; //encodeURIComponent(msg.parsed.headers.from);

      const isRe = reOrFwd != "fw";
      var sub = encodeURIComponent(msg.parsed.headers.subject); //works even with special chars. + "'432423<>!!@321312?%&^*()'
      var prefixSub = isRe ? "Re: " : "Fwd: ";
      sub = prefixSub + sub;
      //  var body = encodeURIComponent(msg.parsed.html);

      const sepFwd = "\n\n---------- Forwarded message ----------\n";
      const sepRe = "\n\n---------- Reply above this line ----------\n";
      var sepBody = isRe ? sepRe : sepFwd;
      var body = encodeURIComponent(sepBody + msg.parsed.text);
      // mailto:SEDN?subject=SUB&body=BODYYY

      //return `mailto:${from}?subject=${sub}`;

      return `mailto:${from}?subject=${sub}&body=${body}}`;
    },

    getMsgDetailPath(id) {
      //depending on current base path, return the path to the message detail route
      // in vue get first parth of the path.
      var topPath = this.$route.path.split("/")[1];

      if (topPath == "all") return "/all/" + id;
      if (topPath == "promos") return "/promos/" + id;
      if (topPath == "updates") return "/updates/" + id;
      if (topPath == "team") return "/team/" + id;
      return "/home/" + id;
    },
    showMessage: function (msg, index) {
      //use vue vars
      var msgCard = document.getElementById("msg-card-" + index);
      var msgPane = document.getElementById("message-pane");
      var msgPreview = document.querySelector(".message-preview .content");
    },
  },
};
</script>

<style>
.att img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.msg {
  /* height: 200px;*/

  margin-top: 18px;
  overflow: hidden;
  border: blue 2px solid;
  transition: all 0.3s ease-in-out;
}
/*
.msg.expanded {
  max-height: 500px;
}
.msg.expanded2 {
  max-height: 9600px;
}
*/
.msg .whitefill {
  max-height: 200px;
  transition: all 0.2s ease-in-out;
  -webkit-mask-image: linear-gradient(
    0deg,
    transparent 20px,
    rgba(0, 0, 0, 0.2) 40px,
    rgba(0, 0, 0, 0.8) 80px,
    red 100px
  );
  /*
  -webkit-mask-image: linear-gradient(0deg, transparent 16px, red 66px);
  */
  /* 0deg = down, 90deg = left, 180deg = top, 270deg = right */
}
.msg.expanded .whitefill {
  max-height: 600px;

  -webkit-mask-image: linear-gradient(0deg, transparent 16px, red 120px);
}

.msg.expanded2 .whitefill {
  height: auto;
  max-height: 9600px;
  transition: all 0.3s linear;
  -webkit-mask-image: none;
}

.msg.read {
  border: #00caff00 2px solid;
}

/*  on mobile, educe padding of .msg  */
@media screen and (max-width: 768px) {
  .msg .card-content {
    padding: 8px 8px !important;
  }
  .msg {
    border-radius: 2px !important;
  }
}

.sniped.code {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 70px;
  letter-spacing: 15px;
  background-color: #fafafa;
  border-radius: 20px;
  padding: 4px 20px;

  color: #333;
  cursor: pointer;
  display: inline-block;
  /* transition all props */
  transition: all 0.3s;
}
.sniped.code:hover {
  background-color: #eee;
}
.sniped.code.copied {
  /* green*/
  background-color: #e6ffe6;
}
</style>
