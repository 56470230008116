<template>
  <div id="app">
    <!-- <div class="box">
      {{ updateExists }} == updateExists

      <br />

      222

      <b-snackbar :active.sync="updateExists" :duration="0" type="is-primary" position="is-bottom-right">
        An update is available
        <b-button @click="refreshApp" type="is-text"> Update </b-button>
      </b-snackbar>
     

      <br />
      <b-snackbar v-show="updateExists" :duration="3000" type="is-primary" position="is-bottom-left">
        An update is available222
        <b-button @click="refreshApp" type="is-text"> Update222 </b-button>
      </b-snackbar>

       
    </div>  -->

    <div id="nav">
      <!-- -->
      <Navbar> </Navbar>
      <!--  
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
      -->
    </div>
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";

//import css file from bulma-pricingtable.min.css module npm
import "bulma-pricingtable/dist/css/bulma-pricingtable.min.css";

import update from "./mixins/swUpdate";

export default {
  mixins: [update],
  components: {
    Navbar,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "inbox", //"ApiBros 😎",
    // all titles will be injected into this template
    titleTemplate: "%s - BYE ", //🍔  ",
    meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
};
</script>

<style lang="scss">
kbd {
  /* keyboard shortcut styling */
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  margin: 0 0.2em;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  background-color: #f7f7f7;
}

.empty {
  text-align: center;
}
.text-align-center {
  text-align: center;
}

body,
html {
  background: #fcfcfc;
  min-height: 100vh;
}
#app {
  font-family: "Basier Square Regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active:not(.noUnderline) {
      color: black;
      text-decoration: underline;
    }
  }
}
.navbar {
  background: transparent !important;
}

// PLUGINS
.bro_code pre {
  padding: 0; // fixes a bug in the code bloc k lib
}
.bro_error {
  background: red !important;
}
.bro_error pre code {
  background: rgb(255, 218, 218) !important;
}

.jsoneditor-menu {
  display: none !important;
}

/// PAGES
.breadcrumb.is-xlarge {
  font-size: 2rem;
  font-weight: 300;
  color: #2c3e50;
}

.breadcrumb.is-xlarge a {
  color: #000;
}

.breadcrumb .projectName a {
  color: #ccc;
}
</style>
