<template>
  <div>
    <b-modal v-model="isDetailModalActive" @close="closeModal">
      <div class="MODAL message hero is-fullheight" :class="{ 'is-hidden': false }" id="message-pane">
        <!-- <router-view></router-view> -->
        <MessageDetailsModal :email="selectedEmail" />
      </div>
    </b-modal>

    <div class="columns" id="feed">
      <aside class="column is-narrow aside h is-fullheight is-hidden-touch">
        <div>
          <!--  
          <div class="compose has-text-centered">
            <a class="button is-dark is-block is-bold">
              <span class="compose">Compose</span>
            </a>
          </div> -->

          <div class="main">
            <h3 class="title is-7">me@invt.co</h3>
            <router-link
              v-for="(item, index) in navItems"
              :key="index"
              :to="item.to"
              :class="['item', { active: index === navActiveIndex }]"
            >
              <span class="icon"><i :class="item.icon"></i></span>
              <span class="name">{{ item.name }}</span>
            </router-link>
            <hr />
            <h3 class="title is-7">{{ $store.state.userId }}@bye.email</h3>

            <!-- legacey 

          <a href="#" class="item active"
            ><span class="icon"><i class="fas fa-inbox"></i></span><span class="name">Inbox</span></a
          >

          <a href="#" class="item"
            ><span class="icon"><i class="fa fa-star"></i></span><span class="name">Starred</span></a
          >
          <a href="#" class="item"
            ><span class="icon"><i class="fal fa-envelope"></i></span><span class="name">Sent Mail</span></a
          >
          <a href="#" class="item"
            ><span class="icon"><i class="fa fa-folder-o"></i></span><span class="name">Folders</span></a
          >
          --></div>
        </div>
      </aside>

      <div class="column messages is-fullheight" id="message-feed">
        <div class="container" style="max-width: 700px">
          <h3 class="title is-4">
            Recent Messages
            <DotDrop
              :items="[
                //   { t: 'Import CSV', icon: 'far fa-file-csv', to: `design/import` },
                { t: 'Refresh', icon: 'far fa-sync', click: refreshMessages },
                // { t: 'Export...', icon: 'far fa-file-csv', to: `design/export` },
              ]"
            />
          </h3>

          <!-- 
          <div class="action-buttons">
            <div class="control is-grouped">
              <a class="button is-small"><i class="fa fa-chevron-down"></i></a>
              <a @click="getMessages" class="button is-small"><i class="fa fa-sync"></i></a>
            </div>
            <div class="control is-grouped">
              <a class="button is-small"><i class="fa fa-inbox"></i></a>
              <a class="button is-small"><i class="fa fa-exclamation-circle"></i></a>
              <a class="button is-small"><i class="fa fa-trash-o"></i></a>
            </div>
            <div class="control is-grouped">
              <a class="button is-small"><i class="fa fa-folder"></i></a>
              <a class="button is-small"><i class="fa fa-tag"></i></a>
            </div>
            <div class="control is-grouped pg">
              <div class="title">{{ paginate.pointer.start }}-{{ paginate.pointer.end }} of {{ paginate.total }}</div>
              <a class="button is-link"><i class="fa fa-chevron-left"></i></a>
              <a class="button is-link"><i class="fa fa-chevron-right"></i></a>
            </div>
          </div>  -->

          <div class="inbox-messages" id="inbox-messages">
            <div
              v-for="(msg, index) in messages"
              :key="msg.id"
              v-bind:id="'msg-card-' + index"
              @click="showMessage(msg, index)"
              v-bind:data-preview-id="index"
            >
              <feedCard :msg="msg" :index="String('d' + index + 'FC')" :showMessage="showMessage" />
              <!-- TODO: AMKE CARDFEED COMPONENT" -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import faker from 'faker'
//import VuePaginate from "vue-paginate";
/*
window.inbox = {};
            window.paginate = {
                total: Math.random() * (54236 - 100) + 3
            }
            for (var i = 0; i <= 10; i++) {
                window.inbox[i] = {
                    from: faker.name.findName(),
                    timestamp: null,
                    subject: faker.lorem.sentence().substring(0, 40),
                    snippet: faker.lorem.lines(),
                    fullMail: window.faker.lorem.paragraphs(faker.random.number(40)),
                    email: faker.internet.email()
                };
            }
*/

//export vue compoenn

import Avatar from "vue-avatar-component";
import MessageDetailsModal from "../components/MessageDetailsModal.vue";
// import elipsis from utils
import { elipsis, timeAgo } from "../common/utils";

// DotDrop
import DotDrop from "../components/DotDrop.vue";
//feedCard
import feedCard from "../components/feedCard.vue";

export default {
  name: "inbox",
  components: {
    feedCard,
    DotDrop,
    MessageDetailsModal,
    // VuePaginate
  },
  //props for messages
  data() {
    return {
      messages: [],

      navActiveIndex: 0, // the index of the active item
      navItems: [
        { icon: "fal fa-home", name: "Home", to: "/" },
        /*      { icon: "fal fa-inbox", name: "All", to: "/all" },

    { icon: "fal fa-star", name: "Starred", to: "/starred" },
        { icon: "fal fa-bell", name: "Updates", to: "/updates" },
        { icon: "fal fa-trash", name: "Promos", to: "/promos" },
        { icon: "fal fa-users", name: "Team", to: "/team" },

    
        { icon: "fa fa-star", name: "Starred", to: "/starred" },
        { icon: "fal fa-envelope", name: "Sent Mail", to: "/sent" },
        { icon: "fa fa-folder-o", name: "Folders", to: "/folders" },
          */
      ],
      paginate: {
        pointer: {
          start: 1,
          end: 10,
        },
        total: 100,
      },
    };
  },
  //load msg from API on load
  mounted() {
    this.getMessages();
  },
  computed: {
    //get current route
    isDetailModalActive() {
      return this.$route.params.messageId;
    },
    selectedEmail() {
      return this.messages.filter((message) => {
        console.log(message._id, this.$route.params.messageId, 5558874);
        return message._id === this.$route.params.messageId;
      })[0];
      // return this.$store.state.selectedEmail;
    },
  },
  methods: {
    msgDblCkick(id) {
      var to = this.getMsgDetailPath(id);
      this.$router.push(to);
    },
    //get file icon
    fileIcon: function (filename) {
      var googleDocs = "https://cdn-icons-png.flaticon.com/512/5968/5968517.png";
      return googleDocs;
      //use a cloud service for icons images
      //https://www.iconfinder.com/iconsets/file-icons
      //https://www.iconfinder.com/iconsets/file-icons-2

      let ext = filename.split(".").pop();
      return "/img/file-icons/" + ext + ".png";
    },
    timeAgo: function (date) {
      return timeAgo(date);
    },
    closeModal: function () {
      //do reouter back previous url
      this.$router.go(-1);
      //this.$router.push({ name: "inbox" });
    },
    elipsis: function (str, len) {
      return elipsis(str, len);
    },
    //get messages from API
    async getMessages() {
      // alert(32466);
      window.API.me
        .getUserMsgs()
        .then((data) => {
          this.messages = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refreshMessages() {
      this.getMessages().then(() => {
        //toast buefy
        this.$buefy.toast.open({
          message: "Messages up to date",
          type: "is-dark",
          position: "is-top-right",
          duration: 3000,
        });
      });
    },
    closeBtn: function (id) {
      alert(id);
      //call API to delete message, flag it as "closed"
      // and collapse the message card
    },

    getMailtoHref(msg, reOrFwd) {
      var from = msg.parsed.from.address; //encodeURIComponent(msg.parsed.headers.from);

      const isRe = reOrFwd != "fw";
      var sub = encodeURIComponent(msg.parsed.headers.subject); //works even with special chars. + "'432423<>!!@321312?%&^*()'
      var prefixSub = isRe ? "Re: " : "Fwd: ";
      sub = prefixSub + sub;
      //  var body = encodeURIComponent(msg.parsed.html);

      const sepFwd = "\n\n---------- Forwarded message ----------\n";
      const sepRe = "\n\n---------- Reply above this line ----------\n";
      var sepBody = isRe ? sepRe : sepFwd;
      var body = encodeURIComponent(sepBody + msg.parsed.text);
      // mailto:SEDN?subject=SUB&body=BODYYY

      //return `mailto:${from}?subject=${sub}`;

      return `mailto:${from}?subject=${sub}&body=${body}}`;
    },

    getMsgDetailPath(id) {
      //depending on current base path, return the path to the message detail route
      // in vue get first parth of the path.
      var topPath = this.$route.path.split("/")[1];

      if (topPath == "all") return "/all/" + id;
      if (topPath == "promos") return "/promos/" + id;
      if (topPath == "updates") return "/updates/" + id;
      if (topPath == "team") return "/team/" + id;
      return "/home/" + id;
    },
    showMessage: function (msg, index) {
      //use vue vars
      var msgCard = document.getElementById("msg-card-" + index);
      var msgPane = document.getElementById("message-pane");
      var msgPreview = document.querySelector(".message-preview .content");
    },
  },
};
</script>

<style>
body {
  background: #f0f2f5;
}
.msg {
  margin-top: 18px;
  border-radius: 12px !important;
}

/*  on mobile, educe padding of .msg  */
@media screen and (max-width: 768px) {
  .msg .card-content {
    padding: 8px 8px !important;
  }
  .msg {
    border-radius: 2px !important;
  }
}

.nav.is-dark {
  background-color: #232b2d;
  color: #f6f7f7;
}
.nav.is-dark .nav-item a,
.nav.is-dark a.nav-item {
  color: #f6f7f7;
}
.nav.is-dark .nav-item a.button.is-default {
  color: #f6f7f7;
  background-color: transparent;
  border-width: 2px;
}
.nav.menu {
  border-bottom: 1px solid #e1e1e1;
}
.nav.menu .nav-item .icon-btn {
  border: 3px solid #b7c6c9;
  border-radius: 90px;
  padding: 5px 7px;
  color: #b7c6c9;
}
.nav.menu .nav-item.is-active .icon-btn {
  color: #2eb398;
  border: 3px solid #2eb398;
}
.nav.menu .nav-item .icon-btn .fa {
  font-size: 20px;
  color: #b7c6c9;
}
.nav.menu .nav-item.is-active .icon-btn .fa {
  color: #2eb398;
}
.aside {
  width: 200px;
  display: block;
}
.messages {
  display: block;
  margin-bottom: 200px;
  border-right: 1px solid #dedede;
  padding: 40px 20px;
}
.message {
  display: block;
  background-color: #fff;
  padding: 40px 20px;
}
.aside .compose {
  height: 95px;
  margin: 0 -10px;
  padding: 25px 30px;
}
.aside .compose .button {
  color: #f6f7f7;
}
.aside .compose .button .compose {
  font-size: 14px;
  font-weight: 700;
}
.aside .main {
  padding: 40px;
  color: #6f7b7e;
}
.aside .title {
  color: #6f7b7e;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.aside .main .item {
  display: block;
  padding: 10px 0;
  color: #6f7b7e;
}
.aside .main .item.active {
  background-color: #f1f1f1;
  margin: 0 -50px;
  padding-left: 50px;
}
.aside .main .item:active,
.aside .main .item:hover {
  background-color: #f2f2f2;
  margin: 0 -50px;
  padding-left: 50px;
}
.aside .main .icon {
  font-size: 19px;
  margin-right: 30px;
  color: #a0a0a0;
}
.aside .main .name {
  font-size: 15px;
  color: #5d5d5d;
  font-weight: 500;
}
.messages .action-buttons {
  padding: 0;
  margin-top: -20px;
}
.message .action-buttons {
  padding: 0;
  margin-top: -5px;
}
.action-buttons .control.is-grouped {
  display: inline-block;
  margin-right: 30px;
}
.action-buttons .control.is-grouped:last-child {
  margin-right: 0;
}
.action-buttons .control.is-grouped .button:first-child {
  border-radius: 5px 0 0 5px;
}
.action-buttons .control.is-grouped .button:last-child {
  border-radius: 0 5px 5px 0;
}
.action-buttons .control.is-grouped .button {
  margin-right: -5px;
  border-radius: 0;
}
.pg {
  display: inline-block;
  top: 10px;
}
.action-buttons .pg .title {
  display: block;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 3px;
  font-size: 12px;
  color: #AAAAA;
}
.action-buttons .pg a {
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: none;
}
.is-grouped .button {
  background-image: linear-gradient(#f8f8f8, #f1f1f1);
}
.is-grouped .button .fa {
  font-size: 15px;
  color: #aaaaaa;
}
.inbox-messages {
  margin-top: 60px;
}
.message-preview {
  margin-top: 60px;
}
.inbox-messages .card {
  width: 100%;
}
.inbox-messages strong {
  color: #5d5d5d;
}
.inbox-messages .msg-check {
  padding: 0 20px;
}
.inbox-messages .msg-subject {
  padding: 10px 0;
  color: #5d5d5d;
}
.inbox-messages .msg-attachment {
  float: right;
}
.inbox-messages .msg-snippet {
  padding: 5px 20px 0px 5px;
}
.inbox-messages .msg-subject .fa {
  font-size: 14px;
  padding: 3px 0;
}
.inbox-messages .msg-timestamp {
  float: right;
  padding: 0 20px;
  color: #5d5d5d;
}
.message-preview .avatar {
  display: inline-block;
}
.message-preview .top .address {
  display: inline-block;
  padding: 0 20px;
}
.avatar img {
  width: 40px;
  border-radius: 50px;
  border: 2px solid #999;
  padding: 2px;
}
.address .name {
  font-size: 16px;
  font-weight: bold;
}
.address .email {
  font-weight: bold;
  color: #b6c7d1;
}
.card.active {
  background-color: #f5f5f5;
}
</style>
